import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const TeamCarouselPerson = ({ person }) => {
  const borderColor = `border-${person.color}`;
  return (
    <div className='text-neutral-600 flex flex-col gap-3'>
      <div
        style={{ perspective: "1px", overflow: "hidden" }}
        className={`border-4 rounded-full ${borderColor}`}
      >
        <GatsbyImage
          image={person.image.childImageSharp.gatsbyImageData}
          alt={person.name}
        />
      </div>
      <div className=' flex flex-col'>
        <h4 className='text-sm font-medium text-center'>{person.name}</h4>
        <h5 className='text-xs text-center'>{person.position}</h5>
      </div>
    </div>
  );
};

export default TeamCarouselPerson;
