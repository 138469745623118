import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import SectionTitle from "./SectionTitle";
import TeamCarousel from "./TeamCarousel";

const OurTeam = ({ team }) => (
  <section className='relative'>
    <div className='md:flex md:justify-between absolute hidden w-full h-full'>
      <GatsbyImage
        image={team.sectionImgs.bgLeft.childImageSharp.gatsbyImageData}
        alt='design pattern'
        className='mix-blend-multiply lg:w-5/12 xl:w-auto absolute left-0 object-cover object-right w-1/3 h-full'
      />
      <GatsbyImage
        image={team.sectionImgs.bgRight.childImageSharp.gatsbyImageData}
        alt='software pattern'
        className='mix-blend-multiply lg:w-5/12 xl:w-auto absolute right-0 object-cover object-left w-1/3 h-full'
      />
    </div>
    <div className='md:px-20 container flex flex-col gap-20 px-10 pt-20 pb-20 mx-auto'>
      <SectionTitle title={team.sectionTitle} description={team.description} />

      <div>
        <TeamCarousel people={team.people} />
      </div>
    </div>
  </section>
);

export default OurTeam;
