import React from "react";

const SectionTitle = ({ title, description }) => {
  return (
    <div>
      <h2 className='text-3xl font-medium'>{title}</h2>
      {description && (
        <p className='text-neutral-600 mt-3 text-sm'>{description}</p>
      )}
    </div>
  );
};

export default SectionTitle;
