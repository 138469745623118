import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import PageHeader from "../components/Global/PageHeader";
import AboutIntro from "../components/About/AboutIntro";
import OurTeam from "../components/About/OurTeam";
import OurValues from "../components/About/OurValues";
import History from "../components/About/History";

const About = (props) => {
  const { t } = useTranslation();

  const data = {
    pageTitle: t("TR_ABOUT_PAGE_TITLE"),
    about: {
      content: {
        title: t("TR_INDEX_ABOUT_TITLE"),
        text: t("TR_INDEX_ABOUT_TEXT"),
      },
      button: {
        label: t("TR_INDEX_ABOUT_BUTTON"),
        link: "/about",
      },
      banner: {
        text1: t("TR_INDEX_ABOUT_BANNER_TEXT1"),
        text2: t("TR_INDEX_ABOUT_BANNER_TEXT2"),
      },
      image: props.data.aboutBg.childImageSharp.gatsbyImageData,
    },
    values: {
      sectionTitle: t("TR_ABOUT_VALUES_TITLE"),
      description: t("TR_ABOUT_VALUES_DESCRIPTION"),
      values: [
        {
          label: t("TR_ABOUT_VALUES_1"),
          image: props.data.creativitat.childImageSharp.gatsbyImageData,
        },
        {
          label: t("TR_ABOUT_VALUES_2"),
          image: props.data.cercania.childImageSharp.gatsbyImageData,
        },
        {
          label: t("TR_ABOUT_VALUES_3"),
          image: props.data.passio.childImageSharp.gatsbyImageData,
        },
        {
          label: t("TR_ABOUT_VALUES_4"),
          image: props.data.superacio.childImageSharp.gatsbyImageData,
        },
        {
          label: t("TR_ABOUT_VALUES_5"),
          image: props.data.treballenequip.childImageSharp.gatsbyImageData,
        },
      ],
    },
    team: {
      sectionTitle: t("TR_ABOUT_TEAM_TITLE"),
      sectionImgs: {
        bgLeft: props.data.teamBgLeft,
        bgRight: props.data.teamBgRight,
      },
      description: t("TR_ABOUT_TEAM_DESCRIPTION"),
      people: props.data.teamData.nodes.map((p) => p.frontmatter),
    },
    history: {
      sectionTitle: t("TR_ABOUT_HISTORY_TITLE"),
      articles: props.data.historyData.nodes,
    },
  };

  return (
    <div className='text-neutral-700 bg-neutral-50'>
      <Layout>
        {data && (
          <>
            <PageHeader pageTitle={data.pageTitle} />
            <AboutIntro about={data.about} />
            <OurValues values={data.values} />
            <OurTeam team={data.team} />
            <History history={data.history} />
          </>
        )}
      </Layout>
    </div>
  );
};

export default About;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    historyData: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/history/i" }
        frontmatter: { language: { eq: $language } }
      }
      sort: { order: ASC, fields: frontmatter___listOrder }
    ) {
      nodes {
        frontmatter {
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        html
      }
    }
    teamData: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/team/i" } }
    ) {
      nodes {
        frontmatter {
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          name
          position
          color
        }
      }
    }
    creativitat: file(relativePath: { regex: "/creativitat/i" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    cercania: file(relativePath: { regex: "/cercania/i" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    passio: file(relativePath: { regex: "/passio/i" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    superacio: file(relativePath: { regex: "/superacio/i" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    treballenequip: file(relativePath: { regex: "/treball-en-equip/i" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    teamBgLeft: file(relativePath: { regex: "/team-bg-left/i" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    teamBgRight: file(relativePath: { regex: "/team-bg-right/i" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    aboutBg: file(relativePath: { regex: "/about-intro-bg/i" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export const Head = ({ data }) => {
  const title = JSON.parse(data.locales.edges[0].node.data).TR_MENU_ABOUT;

  return (
    <>
      <title>{title} | CADT</title>
    </>
  );
};
