import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const AboutIntro = ({ about }) => (
  <div className='relative z-10 mt-12'>
    <div
      className='md:h-96 -z-10 absolute w-full h-full bg-top bg-no-repeat bg-cover'
      id='about'
    >
      <div
        className='absolute top-0 z-10 w-full h-full'
        style={{
          backgroundImage:
            "linear-gradient(rgba(250, 250, 250, 0) 0%, rgba(250, 250, 250, 0) 55%, rgba(250, 250, 250, 1) 75%)",
        }}
      ></div>
      <GatsbyImage
        image={about.image}
        alt='CADT'
        className='absolute top-0 w-full h-full'
      />
    </div>

    <div className='md:px-20 container flex flex-col gap-20 px-10 pt-20 pb-20 mx-auto'>
      <div className='bg-cadt/90 top-28 md:top-24 md:px-8 md:w-fit md:text-base md:py-4 pl-14 absolute right-0 w-full px-8 py-8 text-xl font-bold text-right text-white'>
        <p>{about.banner.text1}</p>
        <p>{about.banner.text2}</p>
      </div>

      <div className=' pt-[58vh] md:pt-56 md:text-md mb-10 text-sm'>
        <div
          className='max-w-none prose'
          dangerouslySetInnerHTML={{ __html: about.content.text }}
        ></div>
      </div>
    </div>
  </div>
);
export default AboutIntro;
