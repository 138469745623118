import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";

import TeamCarouselPerson from "./TeamCarouselPerson";

const TeamCarousel = ({ people }) => {
  const [randomTeam, setRandomTeam] = useState([]);

  useEffect(() => {
    const randomTeam = people.sort(() => Math.random() - 0.4);
    setRandomTeam(randomTeam);
  }, [people]);

  const swiperSettings = {
    autoplay: { delay: 2000, disableOnInteraction: false },
    loop: true,
    modules: [Autoplay],
    breakpoints: {
      // mobile
      0: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      // 'xs' breakpoint
      450: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      // 'sm' breakpoint
      560: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      // 'md' breakpoint
      768: {
        slidesPerView: 5,
        spaceBetween: 25,
      },
      // 'lg' breakpoint
      1024: {
        slidesPerView: 6,
        spaceBetween: 25,
      },
      // 'xl' breakpoint
      1280: {
        slidesPerView: 7,
        spaceBetween: 30,
      },
    },
  };

  return (
    <Swiper {...swiperSettings}>
      {randomTeam.map((person, i) => (
        <SwiperSlide key={i}>
          <TeamCarouselPerson person={person} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TeamCarousel;
