import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import SectionTitle from "./SectionTitle";

const OurValues = ({ values }) => {
  return (
    <section className='md:pb-20 md:px-20 container px-10 mx-auto'>
      <SectionTitle
        title={values.sectionTitle}
        description={values.description}
      />
      <div className='sm:grid sm:grid-cols-2 sm:gap-5 md:grid-cols-5 mt-20'>
        {values.values.map((value, i) => (
          <div
            key={i}
            className='sm:flex-col md:gap-1 flex items-center justify-center gap-3'
          >
            <h4
              className={`sm:text-sm text-xl sm:order-1 md:order-none text-center ${
                i % 2 === 0 ? "order-1" : ""
              }`}
            >
              {value.label}
            </h4>
            <div className='md:w-full w-2/3'>
              <GatsbyImage image={value.image} alt={value.label} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default OurValues;
