import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import SectionTitle from "./SectionTitle";
const History = ({ history }) => (
  <section className='mb-20'>
    <div className='md:px-20 container flex flex-col gap-20 px-10 pt-10 pb-24 mx-auto'>
      <SectionTitle title={history.sectionTitle} />
    </div>

    {history.articles.map((article, i) => {
      return (
        <article className='feature-list-article' key={i}>
          <GatsbyImage
            image={article.frontmatter.image.childImageSharp.gatsbyImageData}
            alt='About CADT'
            className={i % 2 === 0 ? "sm:order-1" : ""}
          />
          <div className='self-center'>
            <p
              className='feature-list-p prose'
              dangerouslySetInnerHTML={{ __html: article.html }}
            ></p>
          </div>
          <hr className='sm:hidden border-neutral-400' />
        </article>
      );
    })}
  </section>
);

export default History;
